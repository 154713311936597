<template>
  <tr>
    <td class="flight-base-info">
      <div
        class="flight-base-line"
        v-for="(segment, index) in offerItem
          .offerFlightSegment"
        :key="`seg-${index}`"
      >
        <div class="base-top">
          <div class="flight-time dep-time">
            {{ segment.departure.date | dateTime }}
          </div>
          <div class="flight-center">
            <div class="flight-cross-top">
              剩余:<span class="remain">{{
                offerItem.serviceMatched.inventory
              }}</span>
            </div>
            <div class="flight-cross">
              <div class="flight-line"></div>
              <div class="flight-icon"></div>
            </div>
            <div class="flight-cross-bottom">
              {{ segment.marketingCarrier.flightNumber }} 机型:{{
                segment.craftType.craftType
              }}
            </div>
          </div>
          <div class="flight-time arr-time">
            {{ segment.arrival.date | dateTime }}
            <span
              class="flight-time-cross"
              v-if="isMoreDate(segment.arrival.date, segment.departure.date)"
              >+1</span
            >
          </div>
        </div>
        <div class="base-bottom clearfix">
          <span class="flight-term-l">{{
            segment.departure.airportCode | airCity(true)
          }}</span>
          <span class="flight-term-r">{{
            segment.arrival.airportCode | airCity(true)
          }}</span>
        </div>
      </div>
    </td>
    <template v-if="!testMode">
      <td>
        <template v-if="offerItem.serviceMatched.serviceDetailObj.policy.tripType === 'OW'">
          <span>{{ offerItem.serviceMatched.serviceDetailObj.policy.startFltDt1 | day }}</span>-
          <br/>
          <span>{{ offerItem.serviceMatched.serviceDetailObj.policy.endFltDt1 | day }}</span>
        </template>
        <template v-else>
          <span>{{ offerItem.serviceMatched.serviceDetailObj.policy['startFltDt' + trip.tripNo] | day }}</span>-
          <br/>
          <span>{{ offerItem.serviceMatched.serviceDetailObj.policy['endFltDt' + trip.tripNo] | day }}</span>
        </template>
      </td>
      <td>
        {{
          offerItem.serviceMatched.serviceDetailObj
            .policy.minNum
        }}-{{
          offerItem.serviceMatched.serviceDetailObj
            .policy.maxNum
        }}
      </td>
      <td>
        {{
          offerItem.offerFlightSegment[0]
            .marketingCarrier.flightNumber
        }}
      </td>
      <td>
        {{
          offerItem.serviceMatched.serviceDetailObj
            .policy.deptOn | deptOn
        }}
      </td>
      <td>
        {{
          offerItem.serviceMatched.serviceDetailObj
            .policy.days || "不限"
        }}
      </td>
      <td>
        {{ offerItem.fareMarket.amount }}
      </td>
    </template>
    <!-- 测试用数据显示 -->
    <template v-else>
      <td style="textalign: left">
        {{ offerItem.fareMarket.amount }} <br />
        <strong>offerType:</strong
        >{{ offerItem.offerItemType }} <br />
        <strong>serviceCode:</strong
        >{{ offerItem.serviceMatched.serviceCode
        }}<br />
        <strong>serviceName:</strong
        >{{ offerItem.serviceMatched.serviceName
        }}<br />
        <strong>offerItemID:</strong
        >{{ offerItem.offerItemID }}
      </td>
    </template>
    <td>
      <a-button
        v-if="more"
        size="small"
        @click="showItiMore"
        >更多</a-button
      >
      <span v-else>无</span>
    </td>
    <td>
      <a-tooltip
        :visible="showTootip"
      >
        <template slot="title"> 请先选择上一段航班 </template>
        <a-button
          type="primary"
          shape="round"
          :disabled="disabledBtn"
          @click="onOfferItemClick(offerItem)"
          >预订</a-button
        >
      </a-tooltip>
    </td>
  </tr>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FlightListRow',
  props: {
    iti: Object,
    trip: Object,
    offerItem: Object,
    more: Boolean,
    testMode: Boolean
  },
  data: function () {
    return {
      showTootip: false
    }
  },
  computed: {
    disabledBtn () {
      return this.trip.prevTripChecked ? this.offerItem.disabledOrder : false // 前一段已选时才开启禁用样式
    }
  },
  methods: {
    onOfferItemClick (offerItem) {
      if (offerItem.disabledOrder) {
        if (this.trip.tripNo > 1 && !this.trip.prevTripChecked) { // 没有选择前一航段时，提示选择
          this.showTootip = true

          setTimeout(() => {
            this.showTootip = false
          }, 2000)
          return
        }
      }

      this.$emit('click', offerItem)
    },
    showItiMore () {
      this.$emit('showMore')
    },
    isMoreDate (arrTime, depTime) {
      return moment(arrTime).toArray()[2] !== moment(depTime).toArray()[2]
    }
  },
  filters: {
    deptOn (deptOn) {
      return deptOn === 'D' ? '不限' : deptOn
    }
  }
}
</script>
