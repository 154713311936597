<template>
  <div class="joy-app-container">
    <div class="book-wrap">
      <SearchBar
        :loading="queryLoading"
        v-model="searchObj"
        @search="onSearch"
      />
      <section v-show="!queryLoading && tripData.length">
        <section
          class="book-section flights-section"
          v-for="(trip, index) in tripData"
          :key="index"
        >
          <!-- 选择了一段才显示下一段 -->
          <div class="book-section-head">航班信息 {{ tripName(index) }}</div>
          <FlightTrip
            :ref="`trip${index}`"
            :trip="trip"
            @dateChange="onTripDateChange($event, index)"
            @select="onTripSelect($event, index)"
            @cancel="onTripCancel(index)"
          />
        </section>
      </section>
      <section class="flight-section-placeholder" v-show="!tripData.length">
        <a-spin :spinning="queryLoading" v-if="queryLoading" />
        <span v-else>未查询到该航线航班政策价格，请重新查询</span>
      </section>
      <section class="book-section">
        <div class="book-section-head">已选行程</div>
        <div class="book-list-wrap">
          <table
            border="1"
            bordercolor="#e8e8e8"
            class="book-list-table"
            v-if="checkFlightList.length"
          >
            <thead class="book-list-head">
              <tr>
                <th>序号</th>
                <th>航段</th>
                <th>航班号</th>
                <th>航班日期</th>
                <th>价格</th>
                <th>剩余</th>
              </tr>
            </thead>
            <tbody class="book-list-body">
              <tr v-for="(item, index) in checkFlightList" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ item.offerFlightSegment[0].departure.airportCode | airCity(true) }} -
                  {{ item.offerFlightSegment[0].arrival.airportCode | airCity(true)  }}
                </td>
                <td>
                  {{ item.offerFlightSegment[0].operatingCarrier.flightNumber }}
                </td>
                <td>{{ item.offerFlightSegment[0].departure.date | day }}</td>
                <td><a-input size="small" class="price-input" type="number" v-model="item.fareMarket.amount"/></td>
                <td>{{ item.serviceMatched.inventory }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="emtpy-select-td">请选择航段</div>
        </div>
        <BookLimit :list="bookLimit"></BookLimit>
      </section>
      <section class="book-section" style="padding-bottom: 30px">
        <div class="book-section-head">填写需求</div>
        <a-form class="book-form" :form="bookForm" @submit="handleSubmit">
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="座位数"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                required
              >
                <a-input
                  type="number"
                  :min="'0'"
                  class="book-input-radius"
                  v-decorator="[
                    'seat',
                    {
                      rules: [{ validator: handleFieldValidate }],
                      initialValue: '2'
                    },
                  ]"
                >
                </a-input>
                <span class="input-tooltip-text">最少2人起</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="联系人"
                required
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-input
                  class="book-input-radius"
                  v-decorator="[
                    'name',
                    {
                      rules: [{ validator: handleFieldValidate }],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="邮箱"
                :label-col="{ span: 5 }"
                :wrapper-col="{ span: 13 }"
              >
                <a-input
                  class="book-input-radius"
                  v-decorator="[
                    'email',
                    {
                      rules: [{ validator: handleFieldValidate }],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="电话"
                required
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-input
                  class="book-input-radius"
                  v-decorator="[
                    'phone',
                    {
                      rules: [{ validator: handleFieldValidate }],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                label="联系人手机"
                :label-col="{ span: 5 }"
                :wrapper-col="{ span: 13 }"
                required
              >
                <a-input
                  class="book-input-radius"
                  v-decorator="[
                    'mobile',
                    {
                      rules: [{ validator: handleFieldValidate }],
                    },
                  ]"
                >
                </a-input>
                <span class="input-tooltip-text">非常重要</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item
                label="说明"
                :label-col="{ span: 2 }"
                :wrapper-col="{ span: 19 }"
              >
                <a-input
                  type="textarea"
                  class="book-input-radius"
                  v-decorator="[
                    'intro',
                    {
                      rules: [{ validator: handleFieldValidate }],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="19" :offset="2">
              <div class="book-notice">
                <a-icon type="info-circle" />
                <!-- <p>
                  1、审批通过后，系统将以手机短信形式发送到您提交申请的手机号码，请在审批通过后，及时支付和出票。
                </p> -->
                <p>
                  1、如未能及时完成出票，系统将根据航班销售情况进行座位清理。
                </p>
                <p>
                  2、系统将根据代理人申请团队座位数量及实际出票数量对代理人进行评估，并对出票率不达标的代理人账号进行处理。
                </p>
                <p>3、提交团队申请前，请预先查阅国内、国际运价参考信息。</p>
              </div>
            </a-col>
          </a-row>
          <div class="form-footer">
            <a-button
              :loading="submitLoading"
              shape="round"
              type="primary"
              html-type="submit"
              >提交预订</a-button
            >
          </div>
        </a-form>
      </section>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { validate } from '@/utils/validate'
import SearchBar from '@/components/SearchBar'
import FlightTrip from './components/FlightTrip'
import BookLimit from './components/BookLimit/BookLimit.vue'

import { queryFlight, sumbitOrder, getLimit } from '@/api/flight'

import { urlParamsToQuery } from '@/utils/queryFlight'
import flightParser from '@/utils/flightRespParserV3'

const MW_MAP = ['一', '二', '三', '四']
const RT_MAP = ['去程', '返程']

export default {
  name: 'Book',
  components: {
    SearchBar,
    FlightTrip,
    BookLimit
  },
  computed: {
    formItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
        : {}
    }
  },
  data () {
    return {
      formLayout: 'horizontal',
      bookForm: this.$form.createForm(this, { name: 'book_form' }),
      submitLoading: false,
      queryLoading: false,
      searchObj: {},
      checkFlightList: [],
      queryParmas: {
        airline: this.$airSetttings.airline,
        airlineCabins: '',
        offerType: 'ST', // (string, optional): 行程查询类型,: ST/RT/CT/MW/OJ:单程/往返/中转联程/多目的地/缺口 ,
        originDestinations: [],
        passengers: 'ADT|1#CHD|1#INF|1'
      },
      tripData: [],
      bookLimit: []
    }
  },
  watch: {
    checkFlightList (list) {
      if (list.length === this.tripData.length) {
        this.getLimit()
      }
    }
  },
  mounted () {
    if (this.$route.query.query) {
      this.getPathQuery()
      this.fetchFlight()
    } else {
      this.searchObj = {
        type: 'RT',
        flights: [
          {
            depCity: '',
            arrCity: '',
            depDate: moment().add(1, 'd').format('YYYY-MM-DD')
          },
          {
            depCity: '',
            arrCity: '',
            depDate: moment().add(2, 'd').format('YYYY-MM-DD')
          }
        ]
      }
    }
  },
  methods: {
    onSearch (query) {
      this.tripData = []
      this.searchObj = query
      this.fetchFlight()
    },
    tripName (index) {
      const { length } = this.tripData
      if (length > 2) {
        return `第${MW_MAP[index + 1]}程`
      } else if (length < 2) {
        return ''
      } else {
        return RT_MAP[index]
      }
    },
    getPathQuery () {
      const query = urlParamsToQuery(this.$route.query.query)
      this.searchObj = query
    },
    onTripDateChange (date, tripIndex) {
      this.searchObj.flights[tripIndex].depDate = date.format('YYYY-MM-DD')
      this.tripData = []
      this.fetchFlight()
    },
    onTripSelect (offerItem, tripIndex) {
      this.checkFlightList.splice(tripIndex, 1, JSON.parse(JSON.stringify(offerItem)))
      if (tripIndex < this.tripData.length - 1) {
        // 根据前一段选择的过滤后一段的内容
        const nextTrip = this.tripData[tripIndex + 1]
        flightParser.filterTripShowOfferItems(nextTrip, offerItem)
      }
    },
    onTripCancel () {
      // 取消预订时，简单的把所有行程已选航段内容清空。不能只还原一段，因为涉及多段时，还需要前面的航段重新筛选数据，太复杂，第一期先不考虑
      this.checkFlightList.splice(0, this.checkFlightList.length)
      this.tripData.forEach((item, index) => {
        // 取消预订时，把index及之后的行程已选航段内容清空
        this.$refs['trip' + index][0].clearSelected()
        // 取消预订时，还原index及之后的行程过滤的航段
        flightParser.resetTripShowOfferItems(this.tripData[index])
      })
    },
    clearChecked () {
      this.checkFlightList = []
    },
    async fetchFlight () {
      this.clearChecked()
      this.queryLoading = true
      this.queryParmas.offerType = this.searchObj.type
      this.queryParmas.originDestinations = this.searchObj.flights.map(
        (flight) => {
          return {
            departureDate: flight.depDate,
            destination: flight.arrCity,
            origin: flight.depCity
          }
        }
      )

      try {
        const result = await queryFlight(this.queryParmas)
        this.queryLoading = false
        console.log('result', result)

        const trips = this.queryParmas.originDestinations
        const tripType = this.queryParmas.offerType

        if (result.offers && result.offers.length) {
          const tripData = flightParser.parse(result, trips, tripType)
          console.log('tripData', tripData)

          this.tripData = tripData
        } else {
          this.$message.error('未查询到该航线航班政策价格，请重新查询')
        }
      } catch (e) {
        this.queryLoading = false
        this.$message.error(e.message)
      }
    },
    handleFieldValidate (rule, value, callback) {
      validate(
        'book-flight',
        this.bookForm.getFieldsValue(),
        rule.fullField
      ).then(({ valid, error }) => {
        if (valid) {
          callback()
        } else {
          callback(error)
        }
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      if (!this.checkFlightList.length) {
        this.$message.error('您尚未选择航班')
        return
      }

      this.bookForm.validateFields((err, values) => {
        if (!err) {
          this.submitOrder()
        }
      })
    },
    async submitOrder () {
      const form = this.bookForm.getFieldsValue()
      const submitParams = {
        submitUserId: '1',
        concatName: form.name,
        concatPhone: form.phone,
        concatMobile: form.mobile,
        concatEmail: form.email,
        desc: form.intro,
        seat: form.seat + ''
      }
      submitParams.itinerarys = []

      this.checkFlightList.forEach((offerItem) => {
        submitParams.itinerarys.push({
          offerItemId: offerItem.offerItemID,
          netFare: offerItem.fareMarket.amount
        })
      })

      const params = {
        audit: {
          action: 'START'
        },
        bizDataJson: JSON.stringify(submitParams)
      }

      this.submitLoading = true

      try {
        const result = await sumbitOrder(params)
        this.submitLoading = false
        this.$router.push({
          path: '/book/success',
          query: { orderNo: result }
        })
      } catch (err) {
        this.$message.error(err.message)
        this.submitLoading = false
      }
    },
    async getLimit () {
      const params = {
        airline: this.queryParmas.airline,
        amount: 0,
        itinerarys: []
      }

      this.checkFlightList.forEach((offerItem) => {
        params.itinerarys.push({
          offerItemId: offerItem.offerItemID
        })
      })

      try {
        const result = await getLimit(params)

        this.bookLimit = result
      } catch (err) {
        this.$message.error(err.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.book-wrap {
  ::v-deep .search-tabs .tab-item {
    background: none;
    &.active {
      background: #fff;
    }
  }
}

.flight-section-placeholder {
  color: #999;
  padding: 60px 0;
  background: #fff;
  text-align: center;
  margin-top: 20px;
}

.book-section-head {
  color: #333;
  font-size: 18px;
  line-height: 24px;
  padding-left: 16px;
  border-left: 2px solid @joy-orange-color;
  margin-top: 20px;
  margin-bottom: 12px;
}

.book-section {
}

.flight-trip-box {
  + .flight-trip-box {
    margin-top: 15px;
  }
}

::v-deep .book-list-wrap {
  background: #fff;
  padding: 0 8px;
  margin-top: 12px;
}

::v-deep .book-list-head {
  font-size: 12px;
  font-weight: bold;
  line-height: 41px;
}

::v-deep .book-list-body {
  td {
    padding: 10px 0;
  }

  .price-input {
    width: 100px;
  }
}

::v-deep .book-list-table {
  background: #fff;
  width: 100%;
  text-align: center;
  border-color: #e8e8e8;
  border: 0;

  th {
    border-top: 0;
  }

  tr {
    &:last-of-type {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    border-color: #e8e8e8;

    &:first-of-type {
      border-left: 0;
    }

    &:last-of-type {
      border-right: 0;
    }
  }
}

.emtpy-select-td {
  padding: 20px 0;
  color: #999;
  text-align: center;
}

.book-form {
  background: #fff;
  padding: 16px 0;

  .ant-input {
    border: 0;
    background: #f5f7f9;
  }

  .input-tooltip-text {
    position: absolute;
    display: block;
    right: -60px;
    top: -12px;
    font-size: 12px;
    color: red
  }
}

.book-notice {
  background: rgba(243, 152, 0, 0.06);
  border: 1px solid #f7d192;
  border-radius: 2px;
  color: #888;
  padding: 15px 30px;
  position: relative;
  margin: 0 auto;
  font-size: 12px;

  .anticon {
    position: absolute;
    left: 10px;
    top: 21px;
    color: @joy-orange-color;
  }

  p {
    line-height: 22px;
    margin-bottom: 0;
  }
}

.form-footer {
  text-align: center;
  margin-top: 15px;
}
</style>
