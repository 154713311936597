<template>
  <div class="book-list-wrap flight-list-wrap">
    <table border="1" bordercolor="#e8e8e8" class="book-list-table">
      <thead class="book-list-head flight-list-head">
        <tr>
          <th>航班信息</th>
          <template v-if="!testMode">
            <th>政策日期</th>
            <th>人数</th>
            <th>航班号</th>
            <th>班期</th>
            <th>行程天数</th>
          </template>
          <th>参考价格</th>
          <th>更多</th>
          <th>选择</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="!selectedItem">
          <template v-for="(iti, index) in itineraries" >
          <FlightListRow
            :key="index"
            v-if="iti.orgDstDefMatched.minPriceOfferItem"
            :offerItem="iti.orgDstDefMatched.minPriceOfferItem"
            :trip="trip"
            :testMode="testMode"
            :more="iti.orgDstDefMatched.showOfferItems.length > 1"
            @click="onOfferItemClick"
            @showMore="showItiMore(iti)"
          >
          </FlightListRow>
          <tr :key="index + 'MORE'" v-show="iti.showMore">
            <!-- 更多价格列表单独用一个tr -->
            <td colspan="9">
              <FlightInnerList
                v-if="iti.orgDstDefMatched.showOfferItems.length"
                :items="iti.orgDstDefMatched.showOfferItems"
                :trip="trip"
                :testMode="testMode"
                @click="onOfferItemClick"
              />
            </td>
          </tr>
          </template>
        </template>
        <template v-else>
          <FlightSelectRow
            :selectedItem="selectedItem"
            :testMode="testMode"
            :trip="trip"
            @cancel="onCancel"
          >
          </FlightSelectRow>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import FlightListRow from './components/FlightRow.vue'
import FlightSelectRow from './components/FlightSelectRow.vue'
import FlightInnerList from '../FlightInnerList/FlightInnerList.vue'

export default {
  name: 'FlightList',
  components: {
    FlightListRow,
    FlightSelectRow,
    FlightInnerList
  },
  props: {
    trip: {
      type: Object,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      itineraries: [],
      testMode: false,
      selectedItem: null
    }
  },
  created () {
    this.testMode = this.$route.query.testMode === 'true'
    this.itineraries = this.trip.itineraries
  },
  methods: {
    onOfferItemClick (offerItem) {
      offerItem.tripNo = this.trip.tripNo
      this.selectedItem = offerItem
      this.itineraries.forEach((iti) => {
        iti.showMore = false
      })
      this.$emit('change', offerItem)
    },
    onCancel () {
      this.selectedItem = null
      this.$emit('cancel')
    },
    clearSelected () {
      this.selectedItem = null
    },
    showItiMore (iti) {
      iti.showMore = !iti.showMore
      this.$emit('showMore')
    }
  },
  filters: {
    deptOn (deptOn) {
      return deptOn === 'D' ? '不限' : deptOn
    }
  }
}
</script>

<style lang="less">
.flight-base-info {
  font-size: 12px;
  width: 380px;

  .base-top {
    display: table;
    width: 100%;
  }
  .base-bottom {
    margin-top: -8px;
    margin-bottom: 20px;
  }

  .flight-time {
    display: table-cell;
    font-weight: bold;
    font-size: 16px;
    height: 50px;
    vertical-align: middle;
    text-align: center;
    position: relative;
  }

  .flight-time-cross {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    color: @joy-orange-color;
  }

  .flight-center {
    display: table-cell;
    width: 190px;
  }

  .flight-cross-top {
    margin-top: 6px;
    margin-bottom: -10px;
  }

  .flight-cross {
    margin-bottom: -10px;
  }

  .flight-line {
    display: inline-block;
    height: 1px;
    margin-right: 10px;
    background: @joy-orange-color;
    width: 147px;
    vertical-align: middle;
  }

  .flight-icon {
    display: inline-block;
    vertical-align: middle;
    height: 23px;
    width: 23px;
    background: url("~@/assets/images/feiji.png") no-repeat center;
  }

  .flight-term-l {
    float: left;
    margin-left: 38px;
  }
  .flight-term-r {
    float: right;
    margin-right: 38px;
  }
}
</style>
