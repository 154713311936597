// 前端对UNI打包航班查询报文的解析器

// 名词定义：
// 1.旅程trip: 查询的一条出发地-目的地算一个行程；
// 2.行程itinerary: 一个旅程有可能多种行程类型;
// 3.特征航段orgDstDefs: 暂且叫特征航段。里面的orgDstDef值叫特征值def, seg叫特征航段号;
// 一般特征航段的数量和旅程数一致，但行程如果是联程，则特征航段数量为2;
// 4.航段segment: 航段信息：例如：直达就只有一个航段，联程有两个航段

// 关键点
// 1.最终页面展示对应到的内容是是offers[].offerItems[].serviceDTOs[]{}下的服务内容和flightSegment基本航段信息
// 2.最终提交的内容是offers[].offerItems[].offerItemID

// OW时返回CT特殊处理，让主要类型的解析保持简单清晰

import _ from 'lodash'
import moment from 'moment'

const parse = (resp, trips, queryType) => {
  const { dataLists, offers } = _.cloneDeep(resp)
  const { itineraries, flightSegmentList } = dataLists

  // 步骤1 根据查询内容构建旅程基础模型
  const tripList = _.cloneDeep(trips)

  tripList.forEach((trip, index) => {
    trip.tripNo = index + 1 // 补充查询的旅程的序号
    trip.prevTripChecked = false
    trip.itineraries = getTripItineraries(trip)
  })

  function getTripItineraries (trip) {
    const { tripNo } = trip
    let tripItineraries = []
    // 步骤2 根据tripNo找到itineraries下seg对应tropNo的orgDstDef，如果有对应值，则说明此itinerary符合该旅程，最后根据orgDstDef去重itinerary
    let itinerariesUnion = _.filter(itineraries, (iti) => {
      const defMatched = _.find(iti.orgDstDefs, { seg: tripNo })
      iti.defMatched = defMatched
      return typeof defMatched === 'object'
    })

    itinerariesUnion = _.unionBy(itinerariesUnion, (iti) => {
      return iti.defMatched.orgDstDef
    })

    tripItineraries = _.cloneDeep(itinerariesUnion)

    // 步骤3 遍历每个行程的defMatched，找到orgDstDef特征值匹配到的航段信息
    tripItineraries.forEach((iti) => {
      const { defMatched } = iti

      // 为了CT考虑，这块用filter, 不用find
      const flightSegmentMatched = _.filter(flightSegmentList, { segmentKey: defMatched.orgDstDef })

      // flightSegmentMatched放在def下
      defMatched.flightSegment = flightSegmentMatched

      // 步骤4 根据行程的defMatched，遍历offers找到匹配的offerItems
      const matchedOfferItems = []
      offers.forEach((curOffer) => {
        curOffer.offerItems.forEach((offerItem) => {
          const { serviceDTOs } = offerItem

          // service班期的解析比较特殊
          // tripType有"-"匹配时, 只有一个policy.deptOn1，各航段都是这个值;
          // tripType没有"-"匹配时, 各航段有对应到的policy.deptOn1,policy.deptOn2...
          serviceDTOs.forEach((service, serviceIndex) => {
            try {
              service.serviceDetailObj = JSON.parse(service.serviceDetail)

              if (service.tripType.indexOf('-') < 0) {
                service.serviceDetailObj.policy.deptOn = service.serviceDetailObj.policy[`deptOn${serviceIndex + 1}`]
              } else {
                service.serviceDetailObj.policy.deptOn = service.serviceDetailObj.policy.deptOn1
              }
            } catch (e) {
              throw new Error(e)
            }
          })

          let serviceMatched = null
          // 查找跟行程def匹配的sevice
          serviceMatched = _.find(serviceDTOs, (service) => {
            return service.orgDstDefs[0] === defMatched.orgDstDef
          })

          // 如果找到的service不为空，则算这个offerItem是匹配的
          if (serviceMatched) {
            // 根据service的serviceId获取offerItem的展示价格
            const fareMatched = _.find(offerItem.fareDetails, (fare) => {
              return fare.serviceRefs.indexOf(serviceMatched.serviceID) > -1 && fare.passengerRefs[0] === 'ADT'
            })

            const fareMarket = _.find(fareMatched.fares, (fare) => {
              return fare.code === 'MarketFare'
            })

            // 保留原来的一些字段做冗余，方便功能获取
            matchedOfferItems.push({
              ...offerItem,
              serviceMatched: serviceMatched,
              fareMatched: fareMatched,
              fareMarket: fareMarket,
              offerFlightSegment: defMatched.flightSegment,
              offerType: curOffer.offerType,
              disabledOrder: tripNo > 1
            })
          }
        })
      })
      defMatched.offerItems = matchedOfferItems

      // 根据offerItemType、价格、产品代码过滤offerItems避免同样的价格重复展示
      defMatched.unionOfferItems = _.unionBy(matchedOfferItems, (offerItem) => {
        return offerItem.offerItemType + offerItem.fareMarket.amount + offerItem.serviceMatched.serviceCode
      })

      defMatched.offerItemsID = matchedOfferItems.map((item) => { return item.offerItemID })

      defMatched.showOfferItems = defMatched.unionOfferItems // 用于展示的offerItems, 因为可能涉及到过滤，用于放过滤后的内容

      // 获取最低价
      defMatched.minPriceOfferItem = _.minBy(defMatched.showOfferItems, (offerItem) => {
        return offerItem.fareMarket.amount
      })

      iti.orgDstDefMatched = defMatched
      iti.showMore = false
    })

    return tripItineraries
  }

  return tripList
}

// 根据上一段选的offerItem, 过滤当前trip匹配的offerItem
const filterTripShowOfferItems = (trip, prevOfferItem) => {
  trip.prevTripChecked = true

  trip.itineraries.forEach((iti) => {
    iti.orgDstDefMatched.offerItems.forEach((offerItem) => {
      offerItem.disabledOrder = true

      // OW类型为自由组合, 只按类型和时间过滤
      if (prevOfferItem.offerType === 'OW') {
        if (offerItem.offerType === prevOfferItem.offerType &&
          moment(prevOfferItem.offerFlightSegment[0].arrival.date).isBefore(offerItem.offerFlightSegment[0].departure.date, 'minute')) {
          offerItem.disabledOrder = false
        }
      } else {
        // 其他类型为自由组合，多段组合是同一个offerItem下的，所以根据offerItemID过滤
        if (offerItem.offerItemID === prevOfferItem.offerItemID) {
          offerItem.disabledOrder = false
        }
      }
    })

    iti.orgDstDefMatched.showOfferItems = _.sortBy(iti.orgDstDefMatched.offerItems, (item) => { return item.disabledOrder })

    iti.orgDstDefMatched.minPriceOfferItem = iti.orgDstDefMatched.showOfferItems[0]
  })
  return trip
}

// 还原trip的showOfferItems为最开始状态
const resetTripShowOfferItems = (trip, prevOfferItem) => {
  trip.prevTripChecked = false
  trip.itineraries.forEach((iti) => {
    iti.orgDstDefMatched.showOfferItems = iti.orgDstDefMatched.unionOfferItems

    iti.orgDstDefMatched.showOfferItems.forEach((iti) => {
      iti.disabledOrder = trip.tripNo > 1
    })
    iti.orgDstDefMatched.minPriceOfferItem = _.minBy(iti.orgDstDefMatched.showOfferItems, (offerItem) => {
      return offerItem.fareMarket.amount
    })
  })
  return trip
}

export default {
  parse,
  filterTripShowOfferItems,
  resetTripShowOfferItems
}
