<template>
  <tr>
    <td>
      <template v-if="offerItem.serviceMatched.serviceDetailObj.policy.tripType === 'OW'">
        <span>{{ offerItem.serviceMatched.serviceDetailObj.policy.startFltDt1 | day }}</span> - <span>{{ offerItem.serviceMatched.serviceDetailObj.policy.endFltDt1 | day }}</span>
      </template>
      <template v-else>
        <span>{{ offerItem.serviceMatched.serviceDetailObj.policy['startFltDt' + trip.tripNo] | day }}</span> - <span>{{ offerItem.serviceMatched.serviceDetailObj.policy['endFltDt' + trip.tripNo] | day }}</span>
      </template>
    </td>
    <template v-if="!testMode">
      <td>
        {{offerItem.serviceMatched.serviceDetailObj.policy.minNum}}-{{offerItem.serviceMatched.serviceDetailObj.policy.maxNum}}
      </td>
      <td>
        {{ offerItem.offerFlightSegment[0].marketingCarrier.flightNumber }}
      </td>
      <td>
        {{ offerItem.serviceMatched.serviceDetailObj.policy.deptOn | deptOn }}
      </td>
      <td>
        {{ offerItem.serviceMatched.serviceDetailObj.policy.days || "不限" }}
      </td>
      <td>{{ offerItem.fareMarket.amount }}</td>
    </template>
    <template v-else>
      <!-- 测试用数据显示 -->
      <td style="textalign: left">
        {{ offerItem.fareMarket.amount }} <br />
        <strong>offerType:</strong>{{ offerItem.offerItemType }} <br />
        <strong>serviceCode:</strong>{{ offerItem.serviceMatched.serviceCode
        }}<br />
        <strong>serviceName:</strong>{{ offerItem.serviceMatched.serviceName
        }}<br />
        <strong>offerItemID:</strong>{{ offerItem.offerItemID }}
      </td>
    </template>
    <td>
      <a-tooltip :visible="showTootip">
        <template slot="title"> 请先选择上一段航班 </template>
        <a-button
          class="book-btn"
          size="small"
          type="primary"
          shape="round"
          :disabled="disabledBtn"
          @click="onOfferItemClick(offerItem)"
          >预订</a-button
        >
      </a-tooltip>
    </td>
  </tr>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    trip: Object,
    offerItem: Object,
    testMode: Boolean
  },
  data () {
    return {
      showTootip: false
    }
  },
  computed: {
    disabledBtn () {
      return this.trip.prevTripChecked ? this.offerItem.disabledOrder : false // 前一段已选时才开启禁用样式
    }
  },
  methods: {
    onOfferItemClick (offerItem) {
      if (offerItem.disabledOrder) {
        if (this.trip.tripNo > 1 && !this.trip.prevTripChecked) { // 没有选择前一航段时，提示选择
          this.showTootip = true

          setTimeout(() => {
            this.showTootip = false
          }, 2000)
          return
        }
      }

      this.$emit('click', offerItem)
    },
    isMoreDate (arrTime, depTime) {
      return moment(arrTime).toArray()[2] !== moment(depTime).toArray()[2]
    }
  },
  filters: {
    deptOn (deptOn) {
      return deptOn === 'D' ? '不限' : deptOn
    }
  }
}
</script>
