<template>
  <tr>
    <td class="flight-base-info">
      <div
        class="flight-base-line"
        v-for="(segment, index) in selectedItem.offerFlightSegment"
        :key="`seg-${index}`"
      >
        <div class="base-top">
          <div class="flight-time dep-time">
            {{ segment.departure.date | dateTime }}
          </div>
          <div class="flight-center">
            <div class="flight-cross-top">
              剩余:<span class="remain">{{
                selectedItem.serviceMatched.inventory
              }}</span>
            </div>
            <div class="flight-cross">
              <div class="flight-line"></div>
              <div class="flight-icon"></div>
            </div>
            <div class="flight-cross-bottom">
              {{ segment.marketingCarrier.flightNumber }} 机型:{{
                segment.craftType.craftType
              }}
            </div>
          </div>
          <div class="flight-time arr-time">
            {{ segment.arrival.date | dateTime }}
            <span
              class="flight-time-cross"
              v-if="isMoreDate(segment.arrival.date, segment.departure.date)"
              >+1</span>
          </div>
        </div>
        <div class="base-bottom clearfix">
          <span class="flight-term-l">{{
            segment.departure.airportCode | airCity(true)
          }}</span>
          <span class="flight-term-r">{{
            segment.arrival.airportCode | airCity(true)
          }}</span>
        </div>
      </div>
    </td>
    <template v-if="!testMode">
      <td>
        <template v-if="selectedItem.serviceMatched.serviceDetailObj.policy.tripType === 'OW'">
          <span>{{ selectedItem.serviceMatched.serviceDetailObj.policy.startFltDt1 | day }}</span>-
          <br/>
          <span>{{ selectedItem.serviceMatched.serviceDetailObj.policy.endFltDt1 | day }}</span>
        </template>
        <template v-else>
          <span>{{ selectedItem.serviceMatched.serviceDetailObj.policy['startFltDt' + trip.tripNo] | day }}</span>-
          <br/>
          <span>{{ selectedItem.serviceMatched.serviceDetailObj.policy['endFltDt' + trip.tripNo] | day }}</span>
        </template>
      </td>
      <td>
        {{
          selectedItem.serviceMatched.serviceDetailObj
            .policy.minNum
        }}-{{
          selectedItem.serviceMatched.serviceDetailObj
            .policy.maxNum
        }}
      </td>
      <td>
        {{ selectedItem.offerFlightSegment[0].marketingCarrier.flightNumber }}
      </td>
      <td>
        {{
          selectedItem.serviceMatched.serviceDetailObj.policy.deptOn | deptOn
        }}
      </td>
      <td>
        {{ selectedItem.serviceMatched.serviceDetailObj.policy.days || "不限" }}
      </td>
      <td>
        {{ selectedItem.fareMarket.amount }}
      </td>
    </template>
    <!-- 测试用数据显示 -->
    <template v-else>
      <td style="textalign: left">
        {{ selectedItem.fareMarket.amount }} <br />
        <strong>offerType:</strong>{{ selectedItem.offerItemType }} <br />
        <strong>serviceCode:</strong>{{ selectedItem.serviceMatched.serviceCode
        }}<br />
        <strong>serviceName:</strong>{{ selectedItem.serviceMatched.serviceName
        }}<br />
        <strong>offerItemID:</strong>{{ selectedItem.offerItemID }}
      </td>
    </template>
    <td>
      <span>无</span>
    </td>
    <td>
      <a-button shape="round" @click="onCancel()">取消预订</a-button>
    </td>
  </tr>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FlightSelectRow',
  props: {
    selectedItem: Object,
    trip: Object,
    testMode: Boolean
  },
  data: function () {
    return {}
  },
  methods: {
    onCancel () {
      this.$emit('cancel')
    },
    isMoreDate (arrTime, depTime) {
      return moment(arrTime).toArray()[2] !== moment(depTime).toArray()[2]
    }
  },
  filters: {
    deptOn (deptOn) {
      return deptOn === 'D' ? '不限' : deptOn
    }
  }
}
</script>
