<template>
  <div class="flight-inner-table-wrap">
    <table
      border="1"
      bordercolor="#e8e8e8"
      class="book-list-table flight-inner-table"
    >
      <thead class="book-list-head flight-list-head">
        <tr>
          <th>政策日期</th>
          <template v-if="!testMode">
          <th>人数</th>
          <th>航班号</th>
          <th>班期</th>
          <th>行程天数</th>
          <th>参考价格</th>
          </template>
          <th>选择</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="offerItem in items">
          <FlightInnerListRow
           v-if="offerItem"
           :key="offerItem.offerItemId"
           :trip="trip"
           :offerItem="offerItem"
           :testMode="testMode"
           @click="onClick"
          ></FlightInnerListRow>
        </template>

      </tbody>
    </table>
  </div>
</template>

<script>
import FlightInnerListRow from './components/FlightInnerRow.vue'

export default {
  components: {
    FlightInnerListRow
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    trip: Object,
    testMode: Boolean
  },
  methods: {
    onClick (offerItem) {
      this.$emit('click', offerItem)
    }
  },
  filters: {
    deptOn (deptOn) {
      return deptOn === 'D' ? '不限' : deptOn
    }
  }
}
</script>

<style lang="less">
.flight-inner-table-wrap {
  margin: 10px;
  border: 2px solid #eee;
}

.flight-inner-table {
  width: 900px;
  text-align: center;

  .flight-time-cross {
    color: @joy-orange-color;
    font-size: 10px;
  }

  .book-list-head {
    line-height: 30px;
  }

  .book-btn {
    height: 28px
  }
}
</style>
