<template>
  <div class="date-slider">
    <div class="date-slider-prev">
      <span class="date-slider-btn-prev" @click="onPrevClick"><a-icon type="caret-left" /></span>
    </div>
    <div class="date-slider-wrap">
      <div class="date-slider-list">
        <template v-if="dateList.length">
          <div class="date-item"
           v-for="(date, index) in dateList"
           :key="index"
           @click="onDateClilk(date)"
           >
              <div class="date-info" v-if="date" :class="{active: curDateFormat === date.date}">
                <div class="date-week">{{ date.date | short }}  {{ date.date | week }}</div>
                <div class="date-price">
                    <small v-if="date.price">￥</small>{{ date.price || '---' }}
                </div>
              </div>
          </div>
        </template>
      </div>
    </div>
    <div class="date-slider-next">
        <span class="date-slider-btn-next" @click="onNextClick"><a-icon type="caret-right" /></span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'dateSlider',
  props: {
    date: {
      type: String
    }
  },
  computed: {
    curDate () {
      return moment(this.date)
    },
    curDateFormat () {
      return this.curDate.format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      dateList: []
    }
  },
  created () {
    this.buildDateList()
  },
  methods: {
    buildDateList () {
      const dateList = []

      for (let i = 0; i < 7; i++) {
        dateList[i] = {
          date: moment(this.curDate).add(i - 3, 'd').format('YYYY-MM-DD'),
          price: ''
        }
      }

      this.dateList = dateList
    },
    onDateClilk (date) {
      this.$emit('change', moment(date.date))
    },
    onPrevClick () {
      const newDate = moment(this.curDate).subtract(1, 'd')
      this.$emit('change', newDate)
    },
    onNextClick () {
      const newDate = moment(this.curDate).add(1, 'd')
      this.$emit('change', newDate)
    }
  },
  filters: {
    short (date) {
      return moment(date).format('MM-DD')
    },
    week (date) {
      return moment(date).format('ddd')
    }
  }
}
</script>

<style lang="less" scoped>
    .date-slider {
        height: 80px;
        background: #fff;
        position: relative;
    }
    .date-slider-wrap {
        margin: 0 56px;
    }

    .date-slider-list {
        display: table;
        width: 100%;

        .date-item {
            display: table-cell;
            cursor: pointer;

            &:last-of-type {
                .date-info {
                    border-right: 0;
                }
            }
        }

        .date-info {
            font-size: 12px;
            text-align: center;
            margin-top: 18px;
            border-right: 1px solid #ccc;

            &.active {
                color: @joy-orange-color;
            }
        }
        .date-week {

        }
        .date-price {
            font-size: 18px;
        }
    }

    .date-slider-prev,
    .date-slider-next {
        position: absolute;
        top: 19px;
        z-index: 10;
        box-sizing: border-box;

        .date-slider-btn-prev,
        .date-slider-btn-next {
            display: block;
            width: 42px;
            height: 42px;
            line-height: 42px;
            background: @joy-darkblue-color;
            transition: all 0.3s;
            text-align: center;
            border-radius: 2px;

            &:hover {
                background: @joy-orange-color;
            }
        }

        .anticon {
            color: #fff;
        }
    }

    .date-slider-prev {
        left: 0;
        padding-left: 12px;
    }
    .date-slider-next {
        right: 0;
        padding-right: 12px;
    }
</style>
