<template>
  <div class="book-limit-table" v-if="list.length">
    <div class="limit-table-head">直接成交条件</div>
    <table
      border="1"
      bordercolor="#e8e8e8"
      class="book-list-table"
    >
      <thead class="book-list-head">
        <tr>
          <th>序号</th>
          <th>航段</th>
          <!-- <th>出票期限</th> -->
          <th>提前预订天数</th>
          <th>航班搭配</th>
          <th>竞拍航班期限</th>
          <th>直接成交总限额</th>
          <th>直接成交剩余限额</th>
        </tr>
      </thead>
      <tbody class="book-list-body">
        <tr v-for="(item, index) in list" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            {{
              item.depCode | airCity(true)
            }}
            -
            {{ item.arrCode | airCity(true) }}
          </td>
          <td>
              <!-- {{ item.limitAmountDTO.issueDay }}天内（含）航班，成交后{{ item.limitAmountDTO.leftIssueDate }}个小时；<br/>
              {{ item.limitAmountDTO.issueDay }}天外（不含）航班，成交后{{item.limitAmountDTO.rightIssueDate  }}个小时； -->
              {{ item.limitAmountDTO.reFlightNo }}
          </td>
          <td>{{ item.limitAmountDTO.aheadBookDate }}</td>
          <td>{{ item.limitAmountDTO.reFlightNo }}</td>
          <td>{{ item.limitAmountDTO.startValidTime | mountDay }}至{{ item.limitAmountDTO.endValidTime | mountDay }}</td>
          <td>{{ item.limitAmountDTO.auctionLimit }}</td>
          <td>{{ item.limitAmountDTO.auctionRemainAmount || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  props: {
    list: Array
  },
  filters: {
    mountDay (value) {
      return moment(value).format('MM/DD')
    }
  }
}
</script>

<style lang="less" scoped>
.limit-table-head {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  padding-left: 16px;
  border-left: 2px solid @joy-orange-color;
  margin-top: 20px;
  margin-bottom: 12px;
}
</style>
