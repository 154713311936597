<template>
      <div class="flight-trip-box">
            <FlightSlider
              :date="trip.departureDate"
              @change="dateChange"
            />
            <FlightList
              ref='list'
              :trip='trip'
              @cancel="onTripCancel"
              @change="onTripSelect"
            />
        </div>
</template>

<script>
import FlightSlider from '../FlightSlider/FightSlider'
import FlightList from '../FlightList/FlightList'

export default {
  components: {
    FlightSlider,
    FlightList
  },
  props: {
    trip: {
      type: Object,
      default () {
        return []
      }
    }
  },
  methods: {
    onTripSelect (offerItem) {
      this.$emit('select', offerItem)
    },
    onTripCancel () {
      this.$emit('cancel')
    },
    clearSelected () {
      this.$refs.list.clearSelected()
    },
    dateChange (date) {
      this.$emit('dateChange', date)
    }
  }
}
</script>

<style>

</style>
